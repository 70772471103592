import { OptionItem } from '@/common/types';
import { useMemo } from 'react';
import { useHierarchicalProductsQuery } from '../graphql/products.generated';
import { IHierarchicalProduct } from '../types';
import { adjustProductsForSelect } from '../utils';

type ReturnType = {
  hierarchicalProducts: IHierarchicalProduct[];
  selectProductItems: OptionItem[];
};

export const useHierarchicalProducts = (): ReturnType => {
  const { data: dataHierarchicalProducts } = useHierarchicalProductsQuery();

  const hierarchicalProducts = useMemo(() => {
    return dataHierarchicalProducts
      ? (dataHierarchicalProducts.hierarchicalProducts as IHierarchicalProduct[])
      : [];
  }, [dataHierarchicalProducts]);

  const selectProductItems = useMemo(() => {
    return hierarchicalProducts.map(adjustProductsForSelect);
  }, [hierarchicalProducts]);

  return {
    hierarchicalProducts,
    selectProductItems,
  };
};
